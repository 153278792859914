import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useEventsList } from '@wbk/api/hooks';
import MatchListItem from '@/components/match/MatchListItem';
import Fade from '@/components/animation/Fade';
import { GLOBAL_REVALIDATE } from '@/src/constants/revalidate';
import NewsLetterSection from '@/components/common/NewsLetter';
import Hero from '@/components/home/Hero';
import DownloadApps from '@/components/home/DownloadApps';
import SeasonListItem from '@/components/season/SeasonListItem';
import FootballSpinner from '@/components/ui/spinners/FootballSpinner';
import DeflatedBallWrapper from '@/components/error/DeflatedBallWrapper';
import HideOnWebview from '@/components/webview/HideOnWebview';

const IndexPage = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { data, isLoading, error } = useEventsList({
    lang: locale,
    publicOnly: true,
    organizationSlug: process.env.NEXT_PUBLIC_ORGANIZATION_SLUG!,
  });

  if (isLoading) {
    return (
      <div className='mx-auto my-20 max-w-lg space-y-4'>
        <FootballSpinner className='mx-auto h-80 w-80 text-primary-dark' />
      </div>
    );
  }

  if (error || !data) {
    return (
      <DeflatedBallWrapper>
        <div className='mx-auto max-w-xl rounded-lg bg-secondary-light/80 px-6 py-10 shadow-md'>
          <p className='mb-2 text-center text-3xl text-error'>
            {error?.message || 'Something went wrong'}
          </p>
        </div>
      </DeflatedBallWrapper>
    );
  }

  return (
    <>
      <NextSeo
        title={t('tickets:seo.title')}
        description={t('tickets:seo.description')}
        additionalMetaTags={[{ name: 'keywords', content: t('home:seo.keywords') }]}
      />

      <Hero showButton={data.seasons.length > 0} />

      {data.seasons.length > 0 ? (
        <section className='container py-12' id='pass-section'>
          <h2 className='pb-8 text-4xl font-bold text-white'>{t('event:ticket_option')}</h2>
          <ul className='grid grid-cols-1 gap-5 md:grid-cols-2' data-testid='matches-list'>
            {data.seasons.map((season, i) => (
              <Fade key={season._id} as='li' duration={0.3} delay={i * 0.03}>
                <SeasonListItem
                  season={season}
                  data-list='season-list'
                  data-list-name='tickets-page'
                />
              </Fade>
            ))}
          </ul>
        </section>
      ) : null}

      <section className='container py-12'>
        <h2 className='pb-8 text-4xl font-bold text-white'>{t('event:upcoming_events')}</h2>
        {data.events.length > 0 ? (
          <ul className='space-y-4' data-testid='matches-list'>
            {data.events.map((event, i) => (
              <Fade key={event._id} as='li' duration={0.3} delay={i * 0.03}>
                <MatchListItem event={event} data-list='match-list' data-list-name='tickets-page' />
              </Fade>
            ))}
          </ul>
        ) : (
          <div className='px-4 py-12 text-center'>
            <Image
              className='mx-auto'
              src='/icons/common/no-matches.svg'
              width={219}
              height={184}
              alt='No matches'
            />
            <p>{t('tickets:scheduler_will_be_announced')}</p>
            <p>{t('tickets:meanwhile_you_can_newsletter')}</p>
          </div>
        )}
      </section>

      <NewsLetterSection />

      <HideOnWebview>
        <DownloadApps />
      </HideOnWebview>
    </>
  );
};

export const getStaticProps: GetStaticProps<LayoutProps> = async ({ locale = '' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'auth',
        'profile',
        'common',
        'tickets',
        'home',
        'event',
        'auth_common',
      ])),
    },
    revalidate: GLOBAL_REVALIDATE,
  };
};

export default IndexPage;
